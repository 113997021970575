import React, { useEffect, useMemo, useState } from "react";
import "./Dashboard.css";
import { AgGridReact } from "ag-grid-react";
import Select from "react-select";
import CountUp from "react-countup";
import { axiosService } from "../../../services/axios.service";
import { API_URLS } from "../../../services/constants/api_urls";

function Dashboard() {
  const [rowData, setRowData] = useState([
    { make: "Tesla", model: "Model Y", price: 64950, electric: true },
    { make: "Ford", model: "F-Series", price: 33850, electric: false },
    { make: "Toyota", model: "Corolla", price: 29600, electric: false },
  ]);

  const gridOptions = {
    pagination: true,
    domLayout: "autoHeight",
    onGridReady: function (params) {
      params.api.sizeColumnsToFit();
    },
    paginationPageSize: 5,
    paginationPageSizeSelector: [5, 10, 20, 50],
  };

  const options = [
    { value: "option-1", label: "option-1" },
    { value: "option-2", label: "option-2" },
    { value: "option-3", label: "option-3" },
  ];

  const [podCount, setPodCount] = useState(0);
  const [sevenDaysCount, setSevenDaysCount] = useState(0);

  const [columnDefs] = useState([
    {
      headerName: "S No",
      field: "",
      valueGetter: "node.rowIndex + 1",
    },
    {
      headerName: "Username",
      field: "username",
    },
    {
      headerName: "Language",
      field: "subcategory.subcategoryName",
    },
    {
      headerName: "Rank",
      field: "rank",
    },
    {
      headerName: "Score",
      field: "totalScore",
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  const [data, setData] = useState(null);
  const [leaderboard, setLeaderboard] = useState(null);
  useEffect(() => {
    axiosService
      .get(`${API_URLS.GET_DASHBOARD_STATISTICS}`)
      .then((response) => {
        if (response.status === 200) {
          setData(response?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    axiosService
      .get(`${API_URLS.GET_ALL_LEADERBOARD}`)
      .then((response) => {
        if (response.status === 200) {
          setLeaderboard(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    axiosService
      .get(`${API_URLS.GET_PROBLEM_OF_THE_DAY_COUNT}`)
      .then((response) => {
        if (response.status === 200) {
          console.log(response, "resppppppp");
          setPodCount(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    axiosService
      .get(`${API_URLS.GET_SEVENDAYS_COUNT}`)
      .then((response) => {
        if (response.status === 200) {
          console.log(response, "resppppppp");
          setSevenDaysCount(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  console.log(leaderboard, "leaderboard");
  return (
    <div className="dashbord-main-container">
      <div className="flex justify-between flex-wrap gap-[10px]">
        <div className="dashboard-card">
          <h1>Total users</h1>
          <CountUp
            start={0}
            end={data?.totalUsers}
            duration={3}
            className="count"
          />
        </div>
        <div className="dashboard-card">
          <h1>Anonymous users</h1>
          <CountUp
            start={0}
            end={data?.anonymousUsers}
            duration={3}
            className="count"
          />
        </div>
        <div className="dashboard-card">
          <h1>Registered users</h1>
          <CountUp
            start={0}
            end={data?.registeredUsers}
            duration={3}
            className="count"
          />
        </div>
        <div className="dashboard-card">
          <h1>Practice</h1>
          <CountUp
            start={0}
            end={data?.practicingUsers}
            duration={3}
            className="count"
          />
        </div>
        <div className="dashboard-card">
          <h1>Skill Test</h1>
          <CountUp
            start={0}
            end={data?.practicingUsers}
            duration={3}
            className="count"
          />
        </div>

        <div className="dashboard-card">
          <h1>Seven-days Available Problems</h1>
          <CountUp
            start={0}
            end={sevenDaysCount}
            duration={3}
            className="count"
          />
        </div>
        <div className="dashboard-card">
          <h1>Problem of the Day Available Problems</h1>
          <CountUp start={0} end={podCount} duration={3} className="count" />
        </div>
      </div>

      {/* <div className="flex gap-[10px] md:gap-[30px] flex-wrap">
        <div className="flex gap-[5px] items-center flex-wrap">
          <input type="date" className="date-input" />
          <label>to</label>
          <input type="date" className="date-input" />
        </div>
        <Select
          options={options}
          classNamePrefix="custom_select"
          isSearchable={false}
          components={{
            IndicatorSeparator: null,
          }}
        />
      </div> */}
      <div className=" gap-[10px] w-[100%]">
        <div className="ag-theme-quartz">
          <AgGridReact
            rowData={leaderboard}
            columnDefs={columnDefs}
            gridOptions={gridOptions}
            defaultColDef={defaultColDef}
          />
        </div>
        {/* <div className="ag-theme-quartz">
          <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            gridOptions={gridOptions}
          />
        </div> */}
      </div>
    </div>
  );
}

export default Dashboard;
