import React, { useEffect, useMemo, useState } from "react";
import "./Stats.css";
import Select from "react-select";
import SelectDropDownIcon from "../../../assets/icons/selectDropdown.svg";
import { AgGridReact } from "ag-grid-react";
import { Country } from "country-state-city";
import CountUp from "react-countup";
import { axiosService } from "../../../services/axios.service";
import { API_URLS } from "../../../services/constants/api_urls";
import sessionstorageService from "../../../services/sessionstorage.service";

function Stats() {
  const [rowData, setRowData] = useState([
    { username: "Tesla", language: "Java", rank: 1, totalScore: 1000 },
    { username: "Ford", language: "Python", rank: 2, totalScore: 500 },
    { username: "Toyota", language: "C++", rank: 3, totalScore: -10 },
  ]);

  const [selectedLocation, setSelectedLocation] = useState(null);

  const options = [
    { label: "report 1", value: "report1" },
    { label: "report 2", value: "report2" },
    { label: "report 3", value: "report3" },
  ];
  const customDropdownIndicator = () => (
    <img src={SelectDropDownIcon} alt="Dropdown Indicator" />
  );

  const data = {
    totalUsers: 20,
  };
  const [columnDefs] = useState([
    {
      headerName: "S No",
      field: "",
      valueGetter: "node.rowIndex + 1",
    },
    {
      headerName: "Username",
      field: "username",
    },
    {
      headerName: "Language",
      field: "language",
    },
    {
      headerName: "Rank",
      field: "rank",
    },
    {
      headerName: "Score",
      field: "totalScore",
    },
  ]);

  const gridOptions = {
    pagination: true,
    domLayout: "autoHeight",
    onGridReady: function (params) {
      params.api.sizeColumnsToFit();
    },
    paginationPageSize: 5,
    paginationPageSizeSelector: [5, 10, 20, 50],
  };

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  const location =
    Country &&
    Country.getAllCountries().map((locations) => ({
      value: locations?.name.replace(" ", "_"),
      label: locations?.name,
    }));

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [fetchCaregory, setFetchCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [fetchSubCaregory, setFetchSubCategory] = useState([]);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [fetchExercise, setFetchExercise] = useState([]);
  const [exerciseCount, setExerciseCount] = useState(0);

  useEffect(() => {
    axiosService
      .get(
        // `${API_URLS.GET_USER_CATEGORY}?categoryName=${selectedCategory?.value}`
        `${API_URLS.GET_USER_CATEGORY}`
      )
      .then((response) => {
        if (response.status === 200) {
          let values = response?.data?.data?.map((item) => {
            return {
              label: item.replace(/_/g, " "),
              value: item,
            };
          });
          setFetchCategory(values);
          console.log(values, "wwwwwwwwwwwwwwww");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleSelectCategory = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };

  useEffect(() => {
    axiosService
      .get(
        // `${API_URLS.GET_USER_CATEGORY}?categoryName=${selectedCategory?.value}`
        `${API_URLS.GET_USER_SUBCATEGORY}`
      )
      .then((response) => {
        if (response.status === 200) {
          let values = response?.data?.data?.map((item) => {
            return {
              label: item.replace(/_/g, " "),
              value: item,
            };
          });
          setFetchSubCategory(values);
          console.log(values, "wwwwwwwwwwwwwwww");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleSelectSubCategory = (selectedOption) => {
    setSelectedSubCategory(selectedOption);
  };

  useEffect(() => {
    axiosService
      .get(`${API_URLS.GET_USER_EXERCISE}`)
      .then((response) => {
        if (response.status === 200) {
          let values = response?.data?.data?.map((item) => {
            return {
              label: item.replace(/_/g, " "),
              value: item,
            };
          });
          setFetchExercise(values);
          setExerciseCount(values?.length);
          console.log(response, "wwwwwwwwwwwwwwww");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleSelectExercise = (selectedOption) => {
    setSelectedExercise(selectedOption);
  };

  return (
    <div className="flex flex-col gap-[10px] md:gap-[20px] p-[20px]">
      <div className="flex gap-[10px] md:gap-[20px] flex-wrap">
        <Select
          value={selectedLocation}
          onChange={(e) => {
            setSelectedLocation(e);
            // setFieldValue("location", e?.value);
          }}
          options={location}
          placeholder="Select location"
          classNamePrefix="admin_module_select"
          className="admin-select-option"
          isSearchable={false}
          components={{
            DropdownIndicator: customDropdownIndicator,
            IndicatorSeparator: null,
          }}
        />

        <Select
          options={fetchCaregory}
          placeholder="Select Category"
          classNamePrefix="admin_module_select"
          className="admin-select-option"
          isSearchable={false}
          components={{
            DropdownIndicator: customDropdownIndicator,
            IndicatorSeparator: null,
          }}
          value={selectedCategory}
          onChange={handleSelectCategory}
        />

        <Select
          options={fetchSubCaregory}
          placeholder="Select Sub-category"
          classNamePrefix="admin_module_select"
          className="admin-select-option"
          isSearchable={false}
          components={{
            DropdownIndicator: customDropdownIndicator,
            IndicatorSeparator: null,
          }}
          value={selectedSubCategory}
          onChange={handleSelectSubCategory}
        />
        <Select
          options={fetchExercise}
          placeholder="Select Exercise"
          classNamePrefix="admin_module_select"
          className="admin-select-option"
          isSearchable={false}
          components={{
            DropdownIndicator: customDropdownIndicator,
            IndicatorSeparator: null,
          }}
          value={selectedExercise}
          onChange={handleSelectExercise}
        />
        <Select
          options={options}
          placeholder="Select Task"
          classNamePrefix="admin_module_select"
          className="admin-select-option"
          isSearchable={false}
          components={{
            DropdownIndicator: customDropdownIndicator,
            IndicatorSeparator: null,
          }}
          //   value={selectedOption}
          //   onChange={handleSelectChange}
        />
      </div>
      <div className=" gap-[10px] w-[100%]">
        <div className="ag-theme-quartz">
          {rowData && rowData.length > 0 ? (
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              gridOptions={gridOptions}
              defaultColDef={defaultColDef}
            />
          ) : (
            <div
              className="w-full text-center"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
            >
              <p className="empty-report-text">Please select region</p>
            </div>
          )}
        </div>
      </div>
      <div className="dashboard-card-admin">
        <h1>Total users</h1>
        <CountUp start={0} end={exerciseCount} duration={3} className="count" />
      </div>
    </div>
  );
}
export default Stats;
