import React, { useEffect, useState } from "react";
import "./Header.css";
import userAvatar from "../../../../assets/icons/user-avatar.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown, DropdownItem } from "flowbite-react";
import sessionService from "../../../../services/sessionstorage.service";

function Header() {
  const location = useLocation();
  const navigate = useNavigate();

  const [title, setTitle] = useState(null);

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const firstPathSegment = pathParts[1];
    if (firstPathSegment === "dashboard") {
      setTitle("Dashboard");
    } else if (firstPathSegment === "report") {
      setTitle("Report");
    } else {
      setTitle("");
    }
  }, [location.pathname]);

  return (
    <div className="header-main-container">
      <h6 className="page-title-header">{title}</h6>

      <Dropdown
        label={
          <div className="flex items-center gap-[5px]">
            <img src={userAvatar} alt="User Avatar" />
            <p>Admin</p>
          </div>
        }
        dismissOnClick={false}
        class="dropdown-custom"
      >
        <DropdownItem
          onClick={() => {
            sessionService.setUserData();
            navigate("/");
          }}
        >
          Logout
        </DropdownItem>
      </Dropdown>
    </div>
  );
}

export default Header;
