import React, { useState, useRef, useEffect } from "react";
import "./JsonEditor.css";
import { Editor } from "@monaco-editor/react";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import Ajv from "ajv";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosService } from "../../../services/axios.service";
import { API_URLS } from "../../../services/constants/api_urls";


const schema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  type: "object",
  // properties: {
  //   name: { type: "string" },
  //   age: { type: "integer" },
  // },
  // required: ["name", "age"],
};

const validateJson = (json) => {
  try {
    const data = JSON.parse(json);
    const errors = monaco.languages.json.jsonDefaults.jsonSchema.validate(
      data,
      schema
    );
    return errors || [];
  } catch (e) {
    return [
      {
        message: "Invalid JSON",
        range: {
          startLineNumber: 1,
          endLineNumber: 1,
          startColumn: 1,
          endColumn: 1,
        },
      },
    ];
  }
};

const JsonEditor = ({ jsonData, selectedParams }) => {
  console.log(selectedParams, "ffffffffffffffffffff");
  const [value, setValue] = useState(JSON.stringify(jsonData, null, 2));
  const [validationErrors, setValidationErrors] = useState([]);
  const [isJsonValid, setIsJsonValid] = useState(false);
  const editorRef = useRef(null);
  const editorContainerRef = useRef(null);
  const ajv = new Ajv();
  const validate = ajv.compile(schema);
  const [errorLine, setErrorLine] = useState("");

  const handleEditorChange = (value, event) => {
    console.log(value, "rrrrrrrrrrrrrrrrrrrrrrr");
    setValue(value);
    const errors = validateJson(value);
    const markers = errors.map((error) => ({
      severity: monaco.MarkerSeverity.Error,
      startLineNumber: error.range.startLineNumber,
      endLineNumber: error.range.endLineNumber,
      startColumn: error.range.startColumn,
      endColumn: error.range.endColumn,
      message: error.message,
    }));
    setValidationErrors(markers);
    setIsJsonValid(false);
  };

  const handleValidate = () => {
    try {
      const jsonData = JSON.parse(value);
      const valid = validate(jsonData);
      if (!valid) {
        setValidationErrors(
          (validate.errors || []).map((err) => ({
            severity: monaco.MarkerSeverity.Error,
            startLineNumber: err.instancePath.split("/").length,
            endLineNumber: err.instancePath.split("/").length,
            startColumn: 1,
            endColumn: 1,
            message: err.message,
          }))
        );
        console.log(validate, "erroe erroe");
        toast.error("JSON not validated");
        setIsJsonValid(false);
      } else {
        setValidationErrors([]);
        console.log("success");
        setErrorLine("");
        toast.success("JSON added Successfully!");
        setIsJsonValid(true);
      }
    } catch (e) {
      console.log(e);
      setErrorLine(e.message);
      setValidationErrors([
        {
          severity: monaco.MarkerSeverity.Error,
          startLineNumber: 1,
          endLineNumber: 1,
          startColumn: 1,
          endColumn: 1,
          message: "Invalid JSON",
        },
      ]);
      toast.error("Invalid JSON");
      setIsJsonValid(false);
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (editorRef.current) {
        editorRef.current.layout();
      }
    });

    if (editorContainerRef.current) {
      resizeObserver.observe(editorContainerRef.current);
    }

    return () => {
      if (editorContainerRef.current) {
        resizeObserver.unobserve(editorContainerRef.current);
      }
    };
  }, []);

  const uploadJsonFile = (jsonData, fileName = "data.json") => {
    const jsonString = JSON.stringify(jsonData, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = url;
    link.download = fileName;
    link.click();

    URL.revokeObjectURL(url);
  };

  
  const Upload = async () => {
    try {
      const parsedData = JSON.parse(value);

      const jsonString = JSON.stringify(parsedData, null, 2);
      const blob = new Blob([jsonString], { type: "application/json" });

      const formData = new FormData();
      formData.append("file", blob, selectedParams.fileName);

      // Convert selectedParams to a URLSearchParams string
      const params = new URLSearchParams({
        ...selectedParams,
      }).toString();

      const response = await axiosService.postFile(
        `${API_URLS.GET_USER_JSON_UPDATE}?${params}`,
        formData
      );

      if (response?.status === 200) {
        toast.success("JSON uploaded successfully!");
      } else {
        toast.error("Failed to upload JSON.");
      }
    } catch (error) {
      console.error("Error uploading JSON:", error);
      toast.error("Error: Unable to upload JSON.");
    }
  };

  return (
    <>
      <div
        ref={editorContainerRef}
        style={{
          border: "2px solid #e3e3e3",
          borderRadius: "5px",
          // padding: "20px 10px",
          // width: "100%",
          // height: "100%",
        }}
      >
        <Editor
          height="400px"
          width="99%"
          language="json"
          value={value}
          onChange={handleEditorChange}
          options={{
            automaticLayout: true,
            minimap: { enabled: false },
            lineNumbers: "on",
          }}
          editorDidMount={(editor) => {
            editorRef.current = editor;
            editor.onDidChangeModelContent(() => {
              handleEditorChange(editor.getValue());
            });

            monaco.editor.setModelMarkers(
              editor.getModel(),
              "json-validation",
              validationErrors
            );
          }}
        />
      </div>
      <div className="flex gap-[10px] sm:gap-[15px] mt-[10px] sm:my-[15px] w-full">
        <button onClick={handleValidate} className="admin-validate-btn">
          Validate
        </button>
        <button
          className="admin-submit-btn"
          disabled={!isJsonValid}
          onClick={Upload}
        >
          Upload
        </button>

        {errorLine && (
          <div className="admin-json-error-messages">
            <ul>
              <li>{errorLine}</li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default JsonEditor;
