import React, { useState } from "react";
import Modal from "react-modal";
import "./AddFolderModal.css";
import { axiosService } from "../../../../services/axios.service";
import { API_URLS } from "../../../../services/constants/api_urls";
import { toast } from "react-toastify";

function AddFolderModal({
  isOpen,
  onRequestClose,
  selectedValues,
  fetchInitialDropdownData,
  fetchDropdownData,
}) {
  const [folderName, setFolderName] = useState("");

  console.log(selectedValues, "selectedValues");

  function transformSelectedValues(obj) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([key, value]) => value !== null)
        .map(([key, value]) => [key, value.value])
    );
  }

  const transformedValues = transformSelectedValues(selectedValues);
  console.log(transformedValues, "filtereeee");

  const handleSubmit = async () => {
    const params = new URLSearchParams({
      folderName: folderName,
      ...transformedValues,
    }).toString();

    try {
      const response = await axiosService.post(
        `${API_URLS.CREATE_CATEGORY}?${params}`
      );
      if (response.status === 200) {
        toast.success(response?.data);
        fetchInitialDropdownData();
        fetchDropdownData();
        onRequestClose();
        setFolderName("");
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }

      console.log("Error adding folder:", error?.response?.data?.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="ReactModal__Content"
      overlayClassName="ReactModal__Overlay"
    >
      <h2 className="modal-header">Add New Folder</h2>
      <input
        type="text"
        value={folderName}
        onChange={(e) => setFolderName(e.target.value)}
        placeholder="Enter folder name"
        className="modal-input"
      />
      <div className="modal-buttons">
        <button onClick={handleSubmit} className="modal-button">
          Submit
        </button>
        <button onClick={onRequestClose} className="modal-button cancel">
          Cancel
        </button>
      </div>
    </Modal>
  );
}

export default AddFolderModal;
