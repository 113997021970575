import React, { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "./core/auth/comonents/login/Login";
import Sidebar from "./core/auth/comonents/sidebar/Sidebar";
import Header from "./core/auth/comonents/header/Header";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Report from "./modules/components/report/Report";
import sessionService from "./services/sessionstorage.service";
import Dashboard from "./modules/components/dashboard/Dashboard";
import Assets from "./modules/components/assets/Assets";
import Structure from "./modules/components/structure/Structure";
import EditJson from "./modules/components/edit-json/EditJson";
import Stats from "./modules/components/stats/Stats";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    if (location.pathname === "/") {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
  }, [location]);

  const userData = sessionService.getUserData();

  // useEffect(() => {
  //   if (!userData) {
  //     navigate("/");
  //   }
  // }, [location.pathname]);

  return (
    <div>
      <div className="flex">
        {showNavbar && <Sidebar />}

        <div className="flex flex-col w-full">
          {showNavbar && <Header />}
          <Routes>
            {!showNavbar && <Route path="*" element={<Login />} />}  
            <>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="edit-json" element={<EditJson />} />
              <Route path="assets" element={<Assets />} />
              <Route path="stats" element={<Stats />} />
              <Route path="report" element={<Report />} />
              <Route path="structure" element={<Structure />} />
            </>
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
