import React, { useEffect, useState } from "react";
import "./Login.css";
import JobFi from "../../../../assets/images/JobFi.svg";
import { useNavigate } from "react-router-dom";
import { axiosService } from "../../../../services/axios.service";
import { API_URLS } from "../../../../services/constants/api_urls";
import sessionService from "../../../../services/sessionstorage.service";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (email === "admin" && password === "123") {
    //   navigate("dashboard");
    // } else {
    //   setError("Username and password not match");
    // }
    const credentials = {
      email: email,
      password: password,
    };
    try {
      const response = await axiosService.post(API_URLS.LOGIN, credentials);
      if (response.status === 200) {
        if (response?.data?.userData?.role?.name === "admin") {
          const token = response.headers["authorization"];
          sessionService.setToken(token);
          sessionService.setUserData(response.data.userData);
          navigate("/dashboard");
        } else {
          setError("Bad Credentials");
        }
      } else {
        setError(response?.data?.message);
      }
    } catch (error) {
      setError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    setError("");
  }, [password, email]);

  return (
    <div className="main-login-container">
      <div className="min-h-screen flex flex-col items-center justify-center">
        <div className="h-[100%] w-full py-8 px-6 bg-[#eff9ff] shadow md:max-w-md">
          <a className="flex justify-center">
            <img src={JobFi} />
          </a>
          <form
            onSubmit={handleSubmit}
            className="mt-[30px] flex flex-col gap-[30px]"
          >
            <div>
              <label className="input-label-text">Username</label>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full login-input"
                placeholder="Email address"
              />
            </div>
            <div>
              <label className="input-label-text">Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full login-input"
                placeholder="Password"
              />
            </div>
            {/* <div className="flex items-center justify-end">
              <a
                href="#/"
                className="text-sm font-[400] text-[#187fba] hover:underline"
              >
                Forgot Password?
              </a>
            </div> */}

            <div className="relative flex justify-center mt-[20px]">
              {error && <div className="auth-form-error-message">{error}</div>}
              <button
                type="submit"
                className="font-[700] text-md  w-full h-[40px] rounded text-white bg-[#0173b2]"
              >
                Log in
              </button>
            </div>
            {/* <span className="text-center text-sm">
              Don't have an account?{" "}
              <a
                href="#/signup"
                className="text-sm font-[400] text-[#187fba] hover:underline"
              >
                Create Account
              </a>
            </span> */}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
