import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./Assets.css";
import { API_URLS } from "../../../services/constants/api_urls";
import { axiosService } from "../../../services/axios.service";
import sessionService from "../../../services/sessionstorage.service";
import { toast } from "react-toastify";

const Assets = () => {
  const [file, setFile] = useState(null);
  const [link, setLink] = useState("");

  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*,audio/*",
  });

  useEffect(() => {
    const assetLink = sessionService.getAssetLink();
    if (assetLink) {
      setLink(assetLink);
    }
  }, []);

  // const handleUpload = async () => {
  //   if (file) {
  //     const Data = new FormData();

  //     Data.append("file", file);
  //     try {
  //       const response = await axiosService.postFile(
  //         `${API_URLS?.UPLOAD_FILE}`,
  //         Data
  //       );
  //       if (response?.status === 200) {
  //         setLink(response?.data?.data);
  //         sessionService.setAssetLink(response?.data?.data);
  //         toast("File uploaded successfully");
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //       toast("Failed to upload file");
  //     }
  //   }
  // };

  const handleUpload = async () => {
    if (file) {
      // Check file size (20 MB = 20 * 1024 * 1024 bytes)
      const maxSize = 20 * 1024 * 1024;
      if (file.size > maxSize) {
        toast.error("File size must be less than 20 MB");
        return;
      }

      const Data = new FormData();
      Data.append("file", file);

      try {
        const response = await axiosService.postFile(
          `${API_URLS?.UPLOAD_FILE}`,
          Data
        );
        if (response?.status === 200) {
          setLink(response?.data?.data);
          sessionService.setAssetLink(response?.data?.data);
          toast.success("File uploaded successfully");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to upload file");
      }
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(link).then(
      () => {
        console.log("Link copied to clipboard");
      },
      (err) => {
        console.error("Failed to copy link:", err);
      }
    );
  };

  return (
    <div className="asset-flex asset-flex-col gap-[10px] md:gap-[20px] p-[20px]">
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive ? "dropzone-active" : ""} ${
          file ? "has-file" : ""
        }`}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the file here...</p>
        ) : file ? (
          <p className="file-name">{file.name}</p>
        ) : (
          <p>Drag & drop a file here, or click to select a file</p>
        )}
      </div>

      <button className="asset-btn" onClick={handleUpload}>
        Upload File
      </button>

      {link && (
        <div className="flex gap-[10px]">
          <input type="text" value={link} readOnly />
          <button className="asset-btn" onClick={handleCopy}>
            Copy Link
          </button>
        </div>
      )}
    </div>
  );
};

export default Assets;
