import axios from "axios";
import sessionService from "./sessionstorage.service";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionService.getToken();
    if (token) {
      config.headers["Authorization"] = ` ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const axiosService = {
  post: (url, data) => {
    return axiosInstance.post(url, data);
  },
  get: (url, data) => {
    return axiosInstance.get(url, data);
  },
  postFile: (url, data) => {
    return axiosInstance.post(url, data, {headers: {
      "Content-Type": "multipart/form-data",
    }})
  },
};
