import React, { useState } from "react";
import "./Sidebar.css";
import jobfi from "../../../../assets/images/JobFi.svg";
import jobfi2 from "../../../../assets/images/JobFi.png";
import dashboard_icon from "../../../../assets/images/dashboard.png";
import dashboard_active from "../../../../assets/images/dashboard-active.png";
import report_icon from "../../../../assets/images/report.png";
import report_icon_active from "../../../../assets/images/report-active.png";
import { NavLink } from "react-router-dom";
import CollapseIcon from "../../../../assets/images/collapse.svg";
import CollapseIcon1 from "../../../../assets/images/collapse1.svg";

function Sidebar() {
  const [collapsed, setCollaped] = useState(false);
  return (
    <div
      className={`sidebar-container d-none d-md-block ${
        collapsed ? "collapsed" : "expanded"
      }`}
    >
      <div className={"sidebar-logo-container flex justify-center "}>
        <img
          src={collapsed ? jobfi2 : jobfi}
          className="sidebar-logo"
          alt="jobfi-logo"
        />
      </div>
      <div className="sidebar-list-container">
        <NavLink
          to={"/dashboard"}
          style={({ isActive }) =>
            isActive
              ? { backgroundColor: "#0173B2", color: "#ffffff" }
              : { color: "#000000" }
          }
          className={`sidebar-list-inner-container ${
            collapsed &&
            "flex justify-center sidebar-list-inner-container-collapsed"
          }`}
        >
          <div className="sidebar-list-logo-inactive">
            <img src={dashboard_icon} className="sidebar-list-logo" />
          </div>
          <div className="sidebar-list-logo-active">
            <img src={dashboard_active} className="sidebar-list-logo" />
          </div>

          <span
            className={`sidebar-list-text ${
              collapsed && "collapsed-sidebar-list-text"
            }`}
          >
            Dashboard
          </span>
        </NavLink>
        <NavLink
          to={"/edit-json"}
          style={({ isActive }) =>
            isActive
              ? { backgroundColor: "#0173B2", color: "#ffffff" }
              : { color: "#000000" }
          }
          className={`sidebar-list-inner-container ${
            collapsed &&
            "flex justify-center sidebar-list-inner-container-collapsed"
          }`}
        >
          <div className="sidebar-list-logo-inactive">
            <img src={report_icon} className="sidebar-list-logo" />
          </div>
          <div className="sidebar-list-logo-active">
            <img src={report_icon_active} className="sidebar-list-logo" />
          </div>
          <span
            className={`sidebar-list-text ${
              collapsed && "collapsed-sidebar-list-text"
            }`}
          >
            Edit JSON
          </span>
        </NavLink>
        <NavLink
          to={"/assets"}
          style={({ isActive }) =>
            isActive
              ? { backgroundColor: "#0173B2", color: "#ffffff" }
              : { color: "#000000" }
          }
          className={`sidebar-list-inner-container ${
            collapsed &&
            "flex justify-center sidebar-list-inner-container-collapsed"
          }`}
        >
          <div className="sidebar-list-logo-inactive">
            <img src={report_icon} className="sidebar-list-logo" />
          </div>
          <div className="sidebar-list-logo-active">
            <img src={report_icon_active} className="sidebar-list-logo" />
          </div>
          <span
            className={`sidebar-list-text ${
              collapsed && "collapsed-sidebar-list-text"
            }`}
          >
            Assets
          </span>
        </NavLink>
        <NavLink
          to={"/stats"}
          style={({ isActive }) =>
            isActive
              ? { backgroundColor: "#0173B2", color: "#ffffff" }
              : { color: "#000000" }
          }
          className={`sidebar-list-inner-container ${
            collapsed &&
            "flex justify-center sidebar-list-inner-container-collapsed"
          }`}
        >
          <div className="sidebar-list-logo-inactive">
            <img src={report_icon} className="sidebar-list-logo" />
          </div>
          <div className="sidebar-list-logo-active">
            <img src={report_icon_active} className="sidebar-list-logo" />
          </div>
          <span
            className={`sidebar-list-text ${
              collapsed && "collapsed-sidebar-list-text"
            }`}
          >
            Stats
          </span>
        </NavLink>
        <NavLink
          to={"/structure"}
          style={({ isActive }) =>
            isActive
              ? { backgroundColor: "#0173B2", color: "#ffffff" }
              : { color: "#000000" }
          }
          className={`sidebar-list-inner-container ${
            collapsed &&
            "flex justify-center sidebar-list-inner-container-collapsed"
          }`}
        >
          <div className="sidebar-list-logo-inactive">
            <img src={report_icon} className="sidebar-list-logo" />
          </div>
          <div className="sidebar-list-logo-active">
            <img src={report_icon_active} className="sidebar-list-logo" />
          </div>
          <span
            className={`sidebar-list-text ${
              collapsed && "collapsed-sidebar-list-text"
            }`}
          >
            Structure
          </span>
        </NavLink>

        <div
          className={`sidebar-list-collapse-container ${
            collapsed && " justify-center"
          }`}
          onClick={() => setCollaped(!collapsed)}
        >
          <img
            src={collapsed ? CollapseIcon1 : CollapseIcon}
            className="sidebar-collapse-icon"
            alt="collapse"
          />
          <span className={`sidebar-list-text ${collapsed && "hidden"}`}>
            {collapsed ? "Expand" : "Collapse"}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
