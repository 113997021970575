class SessionStorageService {
  TOKEN_KEY = "token";

  setToken(token) {
    sessionStorage.setItem(this.TOKEN_KEY, token);
  }
  getToken() {
    return sessionStorage.getItem(this.TOKEN_KEY);
  }
  isUserLoggedIn() {
    const token = sessionStorage.getItem(this.TOKEN_KEY);
    return token !== null && token.startsWith("Bearer");
  }
  setUserData(userData) {
    if (typeof sessionStorage !== "undefined") {
      try {
        const userDataString = JSON.stringify(userData);
        sessionStorage.setItem("userData", userDataString);
      } catch (error) {
        console.error("Error storing user data in session storage:", error);
      }
    } else {
      console.error("Session storage is not supported.");
    }
  }
  getUserData() {
    if (typeof sessionStorage !== "undefined") {
      try {
        const userDataString = sessionStorage.getItem("userData");
        return userDataString ? JSON.parse(userDataString) : null;
      } catch (error) {
        console.error(
          "Error retrieving user data from session storage:",
          error
        );
        return null;
      }
    } else {
      console.error("Session storage is not supported.");
      return null;
    }
  }

  clearUserDataFromSession() {
    if (typeof sessionStorage !== "undefined") {
      try {
        sessionStorage.removeItem("userData");
      } catch (error) {
        console.error("Error clearing user data from session storage:", error);
      }
    } else {
      console.error("Session storage is not supported.");
    }
  }

  setAssetLink(link) {
    if (typeof sessionStorage !== "undefined") {
      try {       
        sessionStorage.setItem("AssetLink", link);
      } catch (error) {
        console.error("Error storing Asset link in session storage:", error);
      }
    } else {
      console.error("Session storage is not supported.");
    }
  }

  getAssetLink() {
    if (typeof sessionStorage !== "undefined") {
      try {
        const assetLink = sessionStorage.getItem("AssetLink");
        return assetLink ? assetLink : null;
      } catch (error) {
        console.error(
          "Error retrieving asset link from session storage:",
          error
        );
        return null;
      }
    } else {
      console.error("Session storage is not supported.");
      return null;
    }
  }
}

const sessionService = new SessionStorageService();
export default sessionService;
