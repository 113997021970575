export const API_URLS = {
  LOGIN: `${process.env.REACT_APP_PROD_URL}auth/v1/authenticate`,
  GET_DASHBOARD_STATISTICS: `${process.env.REACT_APP_PROD_URL}user/statistics`,
  GET_ALL_LEADERBOARD: `${process.env.REACT_APP_PROD_URL}user-submission/leaderboard`,

  GET_FETCH_JSON_DATA: `${process.env.REACT_APP_PROD_URL}s3/fetch-json`,
  GET_MODULE_DATA: `${process.env.REACT_APP_PROD_URL}s3/fetch-modules`,
  GET_SUBMODULE_DATA: `${process.env.REACT_APP_PROD_URL}s3/fetch-submodules`,
  GET_CATEGORY_DATA: `${process.env.REACT_APP_PROD_URL}s3/fetch-categories`,
  GET_SUBCATEGORY_DATA: `${process.env.REACT_APP_PROD_URL}s3/fetch-subcategories`,
  GET_FILENAME_DATA: `${process.env.REACT_APP_PROD_URL}s3/fetch-files`,

  GET_USER_CATEGORY: `${process.env.REACT_APP_PROD_URL}user/v1/categories`,
  GET_USER_SUBCATEGORY: `${process.env.REACT_APP_PROD_URL}user/v1/subcategories`,
  GET_USER_EXERCISE: `${process.env.REACT_APP_PROD_URL}user/v1/user-count/exercise`,
  GET_USER_TASK: `${process.env.REACT_APP_PROD_URL}user/v1/user-count/task`,

  GET_USER_JSON_FETCH:`${process.env.REACT_APP_PROD_URL}s3/v1`,
  GET_USER_JSON_UPDATE:`${process.env.REACT_APP_PROD_URL}s3/v1/upload-file`,
  UPLOAD_FILE: `${process.env.REACT_APP_PROD_URL}s3/v1/upload`,

  CREATE_CATEGORY: `${process.env.REACT_APP_PROD_URL}s3/v1/create-category`,
  UPLOAD_NEW_FILE : `${process.env.REACT_APP_PROD_URL}s3/v2/upload-file`,

  GET_SEVENDAYS_COUNT:`${process.env.REACT_APP_PROD_URL}seven-days-challenge/balance-count`,
  GET_PROBLEM_OF_THE_DAY_COUNT:`${process.env.REACT_APP_PROD_URL}problem-of-the-day/balance-count`,
};
