import React, { useState } from "react";
import Modal from "react-modal";
import "./AddFileModal.css";
import { axiosService } from "../../../../services/axios.service";
import { API_URLS } from "../../../../services/constants/api_urls";
import { toast } from "react-toastify";

function AddFileModal({
  isOpen,
  onRequestClose,
  onFolderAdded,
  selectedValues,
  fetchInitialDropdownData,
  fetchDropdownData,
}) {
  const [file, setFile] = useState(null);

  console.log(selectedValues, "selectedValues");

  function transformSelectedValues(obj) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([key, value]) => value !== null)
        .map(([key, value]) => [key, value.value])
    );
  }

  const transformedValues = transformSelectedValues(selectedValues);
  console.log(transformedValues, "filtereeee");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === "application/json") {
      setFile(selectedFile);
    } else {
      toast.error("Please select a valid JSON file");
      setFile(null);
    }
  };

  const handleSubmit = async () => {
    if (!file) {
      toast.error("Please select a JSON file");
      return;
    }
    const params = new URLSearchParams({
      ...transformedValues,
    }).toString();

    console.log(params, "prrrrrrrrrrrrrrrrr");

    const formData = new FormData();
    formData.append("file", file);
    // formData.append("fileName", file.name);
    // Object.entries(transformedValues).forEach(([key, value]) =>
    //   formData.append(key, value)
    // );

    try {
      //   const response = await axiosService.postFile(
      //     `${API_URLS.GET_USER_JSON_UPDATE}`,
      //     formData,
      //     { headers: { "Content-Type": "multipart/form-data" } }
      //   );
      const response = await axiosService.postFile(
        `${API_URLS.UPLOAD_NEW_FILE}?${params}`,
        formData
      );
      if (response.status === 200) {
        toast.success(response?.data);
        onRequestClose();
        // onFolderAdded();
        // onRequestClose();
        fetchInitialDropdownData();
        fetchDropdownData();
        setFile(null);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }

      // toast.error("Error adding file");
      // console.error("Error adding folder:", error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="ReactModal__Content"
      overlayClassName="ReactModal__Overlay"
    >
      <h2 className="modal-header">Add New File</h2>
      <input
        type="file"
        accept=".json"
        onChange={handleFileChange}
        className="modal-input"
      />
      <div className="modal-buttons">
        <button onClick={handleSubmit} className="modal-button">
          Submit
        </button>
        <button onClick={onRequestClose} className="modal-button cancel">
          Cancel
        </button>
      </div>
    </Modal>
  );
}

export default AddFileModal;
